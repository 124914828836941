<template>
	<div class="balance">
		<dashboard-item-header :title="$t('dashboard.balance')" image-source="account-icon.svg">
			<template #contextMenu>
				<context-menu-item
					:title="$t('dashboard.account-statement')"
					icon="account-statement-small.svg"
					icon-alt="Account statement"
					:on-click="navigateToAccountStatement"
				/>
			</template>
		</dashboard-item-header>
		<Spacer height size="xl" />
		<div class="content">
			<div>
				<div class="dougnut-container">
					<DoughnutChart
						:data="chartData"
						:total-balance="totalBalance"
						:animate="animateChart"
						:select-wallet="onWalletClicked"
					/>
				</div>

				<Spacer height size="l" />
				<CButton
					v-if="wallets.data !== null && wallets.data.length >= 2"
					class="hide-on-mobile"
					dark
					@click="navigateTo('/transfer')"
				>
					{{ $t('dashboard.transfer-between-wallets') }}
				</CButton>
				<Spacer height size="l" />
			</div>
			<div class="wallets">
				<div v-if="wallets.error && !wallets.loading" class="non-wallets-info">
					<span class="wallets-error">{{ $t(wallets.error) }}</span>
				</div>
				<div v-if="wallets.loading" class="non-wallets-info">
					<img class="rotate" :src="require('../../assets/icons/pending-2.svg')" />
				</div>
				<div class="wallets-list-container">
					<div v-if="!wallets.loading && !wallets.error" class="wallets-list">
						<WalletRow
							v-for="(wallet, index) in wallets.data"
							:id="wallet.id"
							:key="wallet.id"
							:currency="wallet.currency"
							:balance="(Number(wallet.balance) + Number(wallet.credit)).toString()"
							:border="index !== wallets.data.length - 1"
							:selected="wallet.id === selectedWalletId"
							:on-row-clicked="onWalletClicked"
							:is-demo="wallet.isDemo"
						/>
					</div>
					<div class="wallets-list-shadow" />
				</div>
				<div class="create-wallet-container">
					<button class="create-new-wallet" @click="navigateTo('/create-wallet')">
						<span>{{ $t('dashboard.create-new-wallet') }}</span>
						<img :src="require(`../../assets/create.svg`)" :alt="$t('dashboard.create-new-wallet')" class="image" />
					</button>
					<CButton
						v-if="wallets.data !== null && wallets.data.length >= 2"
						class="hide-on-desktop"
						dark
						@click="navigateTo('/transfer')"
					>
						{{ $t('dashboard.transfer-between-wallets') }}
					</CButton>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import * as Sentry from '@sentry/vue';
import Spacer from '@/shared/spacer/Spacer.vue';
import DashboardItemHeader from '@/shared/dashboard-item-header/DashboardItemHeader';
import DoughnutChart from '@/shared/doughnut-chart/DoughnutChart.vue';
import CButton from '@/shared/cbutton/CButton';
import WalletRow from '@/shared/dashboard-balance/WalletRow';
import apiClient from '../../api';
import ContextMenuItem from '@/shared/context-menu-item/ContextMenuItem';

export default {
	name: 'DashboardBalance',
	components: {
		DashboardItemHeader,
		DoughnutChart,
		WalletRow,
		CButton,
		Spacer,
		ContextMenuItem,
	},
	data() {
		return {
			chartData: [],
			wallets: {
				loading: false,
				data: null,
				error: 0,
			},
			selectedWalletId: null,
			totalWalletsBalance: null,
			totalBalance: 0,
			animateChart: true,
		};
	},
	async mounted() {
		await this.getUsersWallets();
	},
	methods: {
		navigateToAccountStatement() {
			this.navigateTo(`/account-statement/${this.selectedWalletId}`);
		},
		async getUsersWallets() {
			try {
				this.wallets.loading = true;
				const { wallets } = await apiClient.fetchWalletsWithBalances();
				this.wallets.data = wallets.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
				this.selectedWalletId = this.wallets.data[0].id;
				this.createChart();
			} catch (e) {
				console.error(e);
				Sentry.captureException(e);
				this.wallets.error = 'dashboard.failed-load-wallets';
			} finally {
				this.wallets.loading = false;
			}
		},
		onWalletClicked(id) {
			// don't animate on subsequent renders
			this.animateChart = false;
			const wallet = this.wallets.data.find((w) => w.id == id);
			this.selectedWalletId = wallet.id;
			this.createChart();
		},
		createChart() {
			this.totalBalance = this.wallets.data.reduce(
				(acc, wallet) => acc + Number(wallet.balance) + Number(wallet.credit),
				0
			);

			this.chartData = this.wallets.data.map((wallet) => {
				const color = wallet.id === this.selectedWalletId ? '#70C157' : '#17191D';
				const totalBalance = Number(wallet.balance) + Number(wallet.credit);
				return { label: wallet.id, value: totalBalance, color };
			});
		},
		navigateTo(link) {
			if (link.startsWith('/')) {
				this.$router.push({
					path: link,
				});
			} else {
				window.location.href = link;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'src/assets/style/_variables';
@import 'src/assets/style/mixin';

.balance {
	@include card;
}

.content {
	display: grid;
	gap: $spacer-m;
	grid-template-columns: 1fr 1fr;

	@include max-screen($md) {
		grid-template-columns: 1fr;
	}
}

.dougnut-container {
	display: flex;
	justify-content: center;
}

.non-wallets-info {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 200px;
}

.wallets-list-container {
	position: relative;
}

.wallets-list-shadow {
	@include max-screen($md) {
		display: none;
	}

	position: absolute;
	width: 100%;
	height: 60px;
	bottom: 0px;
	background: linear-gradient(to bottom, rgba(29, 32, 36, 0), rgba(29, 32, 36, 1));
	pointer-events: none;
}

.wallets {
	display: flex;
	flex-direction: column;
	gap: 10px;

	&-info {
		align-self: start;
	}

	&-error {
		color: $error-pink;
	}

	&-list {
		position: relative;

		@include min-screen($md) {
			overflow: auto;
			max-height: 400px;
		}

		@include max-screen($md) {
			overflow: visible;
		}
	}
}

.rotate {
	animation: rotation 2s infinite linear;
	width: 50px;
	height: 50px;
}

.create-wallet-container {
	display: flex;
	justify-content: flex-end;

	@include max-screen($md) {
		align-items: center;
		flex-direction: column;
		gap: $label-sm;
	}
}

.hide-on-desktop {
	@include min-screen($md) {
		display: none;
	}
}

.hide-on-mobile {
	@include max-screen($md) {
		display: none;
	}
}

.create-new-wallet {
	display: flex;
	align-items: center;
	gap: $spacer-s;
	background: none;
	border: 0;

	& > span {
		font-weight: $bold;
		font-size: $label-md;
	}
}

@keyframes rotation {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(359deg);
	}
}
</style>
