<template>
	<div class="doughnut-chart">
		<canvas ref="chartCanvas" :width="dimensions" :height="dimensions"></canvas>
		<div class="total">
			<span class="title">{{ $t('dashboard.total-balance') }}</span>
			<span class="value">{{
				Intl.NumberFormat($i18n.locale, { style: 'currency', currency: 'USD' }).format(totalBalance)
			}}</span>
		</div>
	</div>
</template>

<script>
import Chart from 'chart.js';

export default {
	name: 'DoughnutChart',
	props: {
		data: {
			type: Array,
			required: true,
		},
		totalBalance: {
			type: Number,
			required: true,
		},
		animate: {
			type: Boolean,
			required: true,
		},
		selectWallet: {
			type: Function,
			required: true,
		},
	},
	data() {
		return {
			chart: null,
			dimensions: 400,
		};
	},
	watch: {
		data() {
			this.renderChart();
		},
	},
	mounted() {
		this.renderChart();
		window.addEventListener(
			'resize',
			function () {
				if (this.timer) {
					clearTimeout(this.timer);
				}

				this.timer = setTimeout(() => {
					this.resize();
					this.renderChart();
				}, 500);
			}.bind(this)
		);
	},
	methods: {
		resize() {
			if (screen.width < 480) {
				this.dimensions = 300;
			}

			if (screen.width < 380) {
				this.dimensions = 200;
			}

			if (screen.width >= 480) {
				this.dimensions = 400;
			}
		},
		renderChart() {
			if (this.chart) {
				this.chart.destroy();
			}

			const chartData = {
				labels: this.data.map((element) => element.label),
				datasets: [
					{
						data: this.data.map((element) => element.value),
						backgroundColor: this.data.map((element) => element.color),
						borderWidth: 3,
						borderColor: '#1d2024',
					},
				],
			};
			const options = {
				cutoutPercentage: 93,
				legend: {
					display: false,
				},
				tooltips: false,
				animation: {
					animateRotate: this.animate,
				},
				onClick: (_, e) => {
					if (!e[0]) {
						return;
					}
					const id = this.data[e[0]._index].label;
					this.selectWallet(id);
				},
			};

			this.chart = new Chart(this.$refs.chartCanvas, {
				type: 'doughnut',
				data: chartData,
				options,
			});
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'src/assets/style/_variables';

.doughnut-chart {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 400px;

	@include max-screen(480px) {
		width: 300px;
	}

	@include max-screen(380px) {
		width: 200px;
	}
}

.total {
	position: absolute;
	display: flex;
	flex-direction: column;
}

.title {
	font-size: $title-md;

	@include max-screen(380px) {
		font-size: $label-md;
	}
}

.value {
	font-size: $title-lg - 10px;

	@include max-screen(380px) {
		font-size: $label-md;
	}
}

.rotate {
	animation: rotation 2s infinite linear;
	width: 60px;
	height: 60px;
}

@keyframes rotation {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(359deg);
	}
}
</style>
